import {
  all,
  call,
  put,
  select,
  spawn,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';

import {
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';

import { FbInstanceName } from 'modules/shared/firebase/constants/fbInstanceName';
import { firebaseManager } from 'modules/shared/firebase/manager';
import { getUser } from 'redux/sagas/auth/selectors';
import actions from 'redux/actions';

import { api } from 'api';
import { getErrorMessagebyServerName } from 'utils/messages';
import { getUserId } from './selectors';
import { ROUTING } from 'config';
import { history } from 'providers/RouterProvider';

function* callAuth({ payload: { onRedirect } }) {
  try {
    const firebase = firebaseManager.getCurrentInstance();
    const fbUser = yield firebase.getCurrentUser();

    if (!fbUser) {
      yield put(actions.auth.success(null));

      return;
    }

    const response = yield api.getAuthenticatedUser();
    const user = response.data;

    if (user) {
      yield put(actions.auth.success(user));

      onRedirect();
    }
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.auth.failure(errorMessage));
  }
}

function* callSendLinkToEmailForResetPassword({
  payload: { onModalShow, data },
}) {
  try {
    yield api.resetPassword({ ...data, brand: process.env.REACT_APP_BRAND });

    onModalShow();

    yield put(actions.sendLinkToEmailForResetPassword.success(null));
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.sendLinkToEmailForResetPassword.failure(errorMessage));
  }
}

function* callTwoFactorAuth({
  payload: {
    onRedirect,
    data: { code },
  },
}) {
  try {
    const user = yield select(getUser);

    const response = yield api.verifyTfaCodeAndGetAccessToken(user.id, {
      code,
    });

    yield loginProcess(response, function* (dataUser) {
      yield put(actions.logIn.success(dataUser));

      onRedirect();
    });
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.twoFactorAuth.failure(errorMessage));
  }
}

function* loginProcess(response, onAction) {
  const firebase = firebaseManager.getCurrentInstance();

  if (response?.data) {
    yield call(
      signInWithCustomToken,
      firebase.getAuth(),
      response.data.accessToken,
    );

    yield onAction(response.data.user);
  }
}

function* callLogInWithPartner({ payload: { onRedirect, data: user } }) {
  try {
    const response = yield api.loginAsPartner(user.id);

    // Utilize separate firebase instance to login on behalf of partner
    firebaseManager.addInstance(user.brand, FbInstanceName.SECONDARY);

    yield loginProcess(response, function* (user) {
      yield put(actions.logInWithPartner.success(user));

      onRedirect();
    });
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.logInWithPartner.failure(errorMessage));
  }
}

function* callLogIn({ payload: { onRedirect, data } }) {
  try {
    const firebase = firebaseManager.getCurrentInstance();

    const { user: fbUser } = yield call(
      signInWithEmailAndPassword,
      firebase.getAuth(),
      data.email,
      data.password,
    );

    const idToken = yield fbUser.getIdToken();

    const response = yield api.login({
      idToken,
      brand: process.env.REACT_APP_BRAND,
    });

    yield loginProcess(response, function* (dataUser) {
      if (!response?.data?.user?.isTwoFactorAuthEnabled) {
        yield put(actions.logIn.success(dataUser));

        onRedirect();
      } else {
        yield put(actions.setUser(response.data.user));
        yield put(actions.changeIsTwoFactorAuth(true));
      }
    });
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.logIn.failure(errorMessage));
  }
}

function* callLogOut() {
  try {
    const { isTwoFactorAuthEnabled } = yield select(getUser);

    if (isTwoFactorAuthEnabled) {
      yield put(actions.changeIsTwoFactorAuth(false));
    }

    const firebase = firebaseManager.getCurrentInstance();

    yield call(signOut, firebase.getAuth());

    yield put(actions.logOut.success(null));

    history.push({
      search: `?next=${ROUTING.DASHBOARD}`,
    });
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.logOut.failure(errorMessage));
  }
}

function* callLogOutWithPartner() {
  try {
    const user = yield select(getUser);

    const firebase = firebaseManager.getCurrentInstance();

    yield call(signOut, firebase.getAuth());

    // Remove separate firebase instance utilized to login on behalf of partner
    firebaseManager.removeInstance();

    yield put(actions.logOutWithPartner.success(null));

    yield put(
      actions.auth.request({
        onRedirect: () => {
          history.push(`${ROUTING.PARTNERS_PARTNER_PROFILE_PATH}${user.id}`);
        },
      }),
    );
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.logOutWithPartner.failure(errorMessage));
  }
}

function* callGenerateTFAqr() {
  try {
    const user = yield select(getUser);
    const response = yield api.createTfaCode(user.id);

    if (response?.data) {
      yield put(actions.generateTFAqr.success(response.data));
    }
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.generateTFAqr.failure(errorMessage));
  }
}

function* callTurnTwoFactorAuth({ payload }) {
  try {
    const user = yield select(getUser);
    let response;

    if (user.isTwoFactorAuthEnabled) {
      response = yield api.disableTfa(user.id);
    } else {
      response = yield api.enableTfa(user.id, payload);
    }

    if (response?.data) {
      yield put(actions.turnTwoFactorAuth.success(response.data));
    }
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.turnTwoFactorAuth.failure(errorMessage));
  }
}

function* callFindManager() {
  try {
    const userId = yield select(getUserId);

    const response = yield api.getUserManager(userId);

    yield put(actions.findManager.success(response.data));
  } catch (error) {
    yield put(actions.findManager.failure(error));
  }
}

function* callGetBalance() {
  try {
    const { id } = yield select(({ auth }) => auth.user);

    const response = yield api.getUserBalance(id);

    yield put(actions.getBalance.success({ balance: response.data }));
  } catch (error) {
    yield put(actions.getBalance.failure(error));
  }
}

function* watchAuth() {
  yield takeEvery(actions.auth.request, callAuth);
  yield takeEvery(actions.logIn.request, callLogIn);
  yield takeEvery(actions.logInWithPartner.request, callLogInWithPartner);
  yield takeEvery(actions.twoFactorAuth.request, callTwoFactorAuth);
  // Api interceptor may dispatch logout actions multiple times
  // So takeLeading allows to run only the first one and ignore the rest while the first is running
  yield takeLeading(actions.logOut.request, callLogOut);
  yield takeLeading(actions.logOutWithPartner.request, callLogOutWithPartner);
  yield takeEvery(
    actions.sendLinkToEmailForResetPassword.request,
    callSendLinkToEmailForResetPassword,
  );
  yield takeEvery(actions.generateTFAqr.request, callGenerateTFAqr);
  yield takeEvery(actions.turnTwoFactorAuth.request, callTurnTwoFactorAuth);
  yield takeEvery(actions.findManager.request, callFindManager);
  yield takeEvery(actions.getBalance.request, callGetBalance);
}

export default function* authSaga() {
  yield all([watchAuth].map(spawn));
}
