import { api } from 'api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getErrorHandler } from 'utils/getErrorHandler';
import { queryKeys } from 'utils/queryKeys';

export const useTfaAuthByUserId = ({ userId }) => {
  const userQueryKey = queryKeys.user(userId);
  const queryClient = useQueryClient();

  const {
    data: tfaCode,
    mutateAsync: createTfaCode,
    isLoading: isCreatingTfaCode,
  } = useMutation({
    mutationFn: async () => {
      const response = await api.createTfaCode(userId);

      return response.data;
    },
    onError: getErrorHandler('Unable to create two factor authentication code'),
  });

  const {
    mutateAsync: enableTfa,
    isLoading: isEnablingTfa,
    error: enableTfaError,
  } = useMutation({
    mutationFn: async (data) => {
      const response = await api.enableTfa(userId, data);

      return response.data;
    },
    onSuccess: (updatedUser) => {
      queryClient.setQueryData(userQueryKey, updatedUser);
    },
    onError: getErrorHandler('Unable to turn on two factor authentication'),
  });

  const { mutateAsync: disableTfa, isLoading: isDisablingTfa } = useMutation({
    mutationFn: async () => {
      const response = await api.disableTfa(userId);

      return response.data;
    },
    onSuccess: (updatedUser) => {
      queryClient.setQueryData(userQueryKey, updatedUser);
    },
    onError: getErrorHandler('Unable to turn off two factor authentication'),
  });

  return {
    tfaCode,
    createTfaCode,
    isCreatingTfaCode,

    enableTfa,
    isEnablingTfa,
    enableTfaError,

    disableTfa,
    isDisablingTfa,
  };
};
