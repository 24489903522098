import React from 'react';
import { useSelector } from 'react-redux';

import { getUserRole } from 'redux/sagas/auth/selectors';

import { USER_ROLES } from 'utils/constants';

import BrokersRouting from './BrokerRouting';
import StaffRouting from './StaffRouter';
import { useIsStaff } from '../../../../utils/hooks/useIsStaff';

const Routing = () => {
  const isStaff = useIsStaff();

  return isStaff ? <StaffRouting /> : <BrokersRouting />;
};

export default Routing;
